.navbar {
  &.is-launch {
    background-color: black;
    color: white;
    a {
      color: white;
    }

    svg {
      #stripes {
        fill: black !important;
      }

      #leftEye {
        fill: black !important;
      }

      #rightEye {
        fill: black !important;
      }

      #shieldOutline {
        stroke: black !important;
        fill: white !important;
      }
    }
    svg * {
      transition: all 0.5s;
    }

    &.launch-animate-forward {
      animation: darkToLight 0.5s;
      animation-fill-mode: forwards;
      p,
      sup,
      a {
        animation: darkToLight 0.5s;
        animation-fill-mode: forwards;
      }

      svg {
        #stripes {
          fill: white !important;
        }

        #leftEye {
          fill: white !important;
        }

        #rightEye {
          fill: white !important;
        }

        #shieldOutline {
          stroke: white !important;
          fill: black !important;
        }
      }
    }

    &.launch-animate-backward {
      animation: lightToDark 0.5s;
      animation-fill-mode: forwards;
      p,
      sup,
      a {
        animation: lightToDark 0.5s;
        animation-fill-mode: forwards;
      }
    }

    @keyframes darkToLight {
      from {
        background-color: black;
        color: white;
        fill: black;
      }
      to {
        background-color: white;
        color: black;
        fill: white;
      }
    }

    @keyframes lightToDark {
      from {
        background-color: white;
        color: black;
      }
      to {
        background-color: black;
        color: white;
      }
    }
  }
}
