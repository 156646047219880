@charset "utf-8";
@import "./colors.scss";
@import "./variables.scss";

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;

$footer-background-color: white;
$footer-padding: 3rem 1.5rem;
$gap: 32px;
$container-max-width: (2 * $gap) + $max-container-width-large;
@debug $container-max-width;

@import "~bulma/bulma.sass";

@include touch {
  .container {
    padding-left: $gap;
    padding-right: $gap;
  }
}

@include tablet {
  .container.container {
    max-width: $max-container-width-med;
    padding-left: $desktop-gap;
    padding-right: $desktop-gap;
  }
}

@include desktop {
}

@include mobile {
}

@include tablet-only {
}

@include desktop-only {
}

@include widescreen-only {
}

@include fullhd {
}

$tooltip-radius: 6px;

@import "@creativebulma/bulma-tooltip";

:export {
  tablet: $tablet;
  desktop: $desktop;
  widescreenenabled: $widescreen-enabled;
  widescreen: $widescreen;
  fullhd: $fullhd;
  fullhdenabled: $fullhd-enabled;
  radiuslarge: $radius-large;
}
