html,
body {
  /* font-family: "Itim", cursive; */
  /* font-family: "Mallanna", sans-serif; */
  /* font-family: "NTR", sans-serif; */
  /* font-family: "Petrona", serif; */
  /* font-family: "Sniglet", cursive; */
  /* font-family: "Varela Round", sans-serif; */
  /* font-family: "Averia Libre", cursive; */
  /* font-family: "Averia Sans Libre", cursive; */

  font-family: "Maven Pro", sans-serif;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes tooltipBounce {
  0% {
    transform: translate(-100%, 50%);
  }

  100% {
    transform: translate(calc(-100% - 10px), 50%);
  }
}

@keyframes tooltipArrowBounce {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@keyframes iconArrowBounceDownBlack {
  0% {
    transform: translateY(0);
    color: rgb(0, 0, 0);
  }

  70% {
    transform: translateY(12px);
    color: rgb(0, 0, 0);
  }

  100% {
    transform: translateY(12px);
    color: rgb(0, 0, 0);
  }
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    -webkit-filter: hue-rotate(100deg);
  }

  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}

@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(180deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }
}
