$max-container-width-large: 1080px;
$max-container-width-med: 768px;
$desktop-gap: 80px;

@import "./colors.scss";

// Color overrides
$primary: $badger-blue;

:export {
  desktopGap: $desktop-gap;
  maxContainerWidthLarge: $max-container-width-large;
  maxContainerWidthMed: $max-container-width-med;
}
