$twitter: #1d9bf0;
$discord: #8435bde8;
$twitter-white: #fff;
$twitter-heart: rgb(249, 24, 128);
$badger-blue: rgb(28, 44, 66);

$custom-colors: (
  "twitter": (
    $twitter,
    #ffffff,
  ),
  "discord": (
    #8435bde8,
    #ffffff,
  ),
);

:export {
  twitterHeart: $twitter-heart;
  twitterWhite: $twitter-white;
  badgerBlue: $badger-blue;
  discord: $discord;
}
